import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="w-screen text-center pt-16">
      <h1>404: Not Found</h1>
      <p className="pt-10">Sorry, this page does not exist.</p>
    </div>
  </Layout>
);

export default NotFoundPage;
